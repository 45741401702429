// import {
//   Disclosure,
//   DisclosureButton,
//   DisclosurePanel,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuItems,
// } from "@headlessui/react";
// import React, { useContext } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import UserContext from "../context/UserContext";
// import { deleteToken } from "../api/storage";
// import Logo from "../assets/DrHLogo2.png";
// import ProfilePic from "../assets/Profile.png";
// import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import { PlusIcon } from "@heroicons/react/20/solid";

// export default function Navbar() {
//   const [user, setUser] = useContext(UserContext);
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     deleteToken();
//     setUser(false);
//     navigate("/login");
//   };

//   return (
//     <>
//       {user === true ? (
//         <Disclosure as="nav" className="bg-white shadow">
//           <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//             <div className="flex h-16 justify-between">
//               <div className="flex">
//                 <div className="-ml-2 mr-2 flex items-center md:hidden">
//                   {/* Mobile menu button */}
//                   <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
//                     <span className="sr-only">Open main menu</span>
//                     <Bars3Icon
//                       aria-hidden="true"
//                       className="block h-6 w-6 group-data-[open]:hidden"
//                     />
//                     <XMarkIcon
//                       aria-hidden="true"
//                       className="hidden h-6 w-6 group-data-[open]:block"
//                     />
//                   </DisclosureButton>
//                 </div>
//                 <div className="flex flex-shrink-0 items-center">
//                   <img
//                     alt="Your Company"
//                     src={Logo}
//                     className="h-full w-auto"
//                   />
//                 </div>
//                 <div className="hidden md:ml-6 md:flex md:space-x-8">
//                   {/* Use NavLink for navigation */}
//                   <NavLink
//                     to="/"
//                     className={({ isActive }) =>
//                       isActive
//                         ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
//                         : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
//                     }
//                   >
//                     Home
//                   </NavLink>
//                   <NavLink
//                     to="/request"
//                     className={({ isActive }) =>
//                       isActive
//                         ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
//                         : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
//                     }
//                   >
//                     Request
//                   </NavLink>
//                   <NavLink
//                     to="/fileUpload"
//                     className={({ isActive }) =>
//                       isActive
//                         ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
//                         : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
//                     }
//                   >
//                     File Upload
//                   </NavLink>
//                   <NavLink
//                     to="/timeTable"
//                     className={({ isActive }) =>
//                       isActive
//                         ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
//                         : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
//                     }
//                   >
//                     Time Table
//                   </NavLink>
//                 </div>
//               </div>
//               <div className="flex items-center">
//                 <div className="flex-shrink-0">
//                   <button
//                     type="button"
//                     onClick={() => {
//                       navigate("/userRequests");
//                     }}
//                     className="relative inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
//                   >
//                     {/* <PlusIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" /> */}
//                     My Requests
//                   </button>
//                 </div>
//                 <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
//                   <button
//                     type="button"
//                     className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
//                   >
//                     <span className="sr-only">View notifications</span>
//                     <BellIcon aria-hidden="true" className="h-6 w-6" />
//                   </button>

//                   {/* Profile dropdown */}
//                   <Menu as="div" className="relative ml-3">
//                     <div>
//                       <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
//                         <span className="sr-only">Open user menu</span>
//                         <img
//                           alt=""
//                           src={ProfilePic}
//                           className="h-8 w-8 rounded-full"
//                         />
//                       </MenuButton>
//                     </div>
//                     <MenuItems
//                       transition
//                       className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
//                     >
//                       <MenuItem>
//                         <a
//                           href="#"
//                           className="block px-4 py-2 text-sm text-gray-700"
//                         >
//                           Your Profile
//                         </a>
//                       </MenuItem>
//                       <MenuItem>
//                         <a
//                           href="#"
//                           className="block px-4 py-2 text-sm text-gray-700"
//                         >
//                           Settings
//                         </a>
//                       </MenuItem>
//                       <MenuItem>
//                         <a
//                           href="/"
//                           onClick={handleLogout} // Log out on click
//                           className="block px-4 py-2 text-sm text-gray-700"
//                         >
//                           Sign out
//                         </a>
//                       </MenuItem>
//                     </MenuItems>
//                   </Menu>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Mobile menu */}
//           <DisclosurePanel className="md:hidden">
//             <div className="space-y-1 pb-3 pt-2">
//               <DisclosureButton
//                 as={NavLink}
//                 to="/"
//                 className={({ isActive }) =>
//                   isActive
//                     ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700 sm:pl-5 sm:pr-6"
//                     : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
//                 }
//               >
//                 Home
//               </DisclosureButton>
//               <DisclosureButton
//                 as={NavLink}
//                 to="/request"
//                 className={({ isActive }) =>
//                   isActive
//                     ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700 sm:pl-5 sm:pr-6"
//                     : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
//                 }
//               >
//                 Request
//               </DisclosureButton>
//               <DisclosureButton
//                 as={NavLink}
//                 to="/fileUpload"
//                 className={({ isActive }) =>
//                   isActive
//                     ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700 sm:pl-5 sm:pr-6"
//                     : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
//                 }
//               >
//                 File Upload
//               </DisclosureButton>
//               <DisclosureButton
//                 as={NavLink}
//                 to="/timeTable"
//                 className={({ isActive }) =>
//                   isActive
//                     ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700 sm:pl-5 sm:pr-6"
//                     : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
//                 }
//               >
//                 Time Table
//               </DisclosureButton>
//             </div>
//             <div className="border-t border-gray-200 pb-3 pt-4">
//               <div className="flex items-center px-4">
//                 <div className="flex-shrink-0">
//                   <img
//                     alt=""
//                     src={ProfilePic}
//                     className="h-10 w-10 rounded-full"
//                   />
//                 </div>
//                 <div className="ml-3">
//                   <div className="text-base font-medium text-gray-800">
//                     Tom Cook
//                   </div>
//                   <div className="text-sm font-medium text-gray-500">
//                     tom@example.com
//                   </div>
//                 </div>
//                 <button
//                   type="button"
//                   className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
//                 >
//                   <span className="sr-only">View notifications</span>
//                   <BellIcon aria-hidden="true" className="h-6 w-6" />
//                 </button>
//               </div>
//             </div>
//           </DisclosurePanel>
//         </Disclosure>
//       ) : (
//         <Disclosure as="nav" className="bg-white shadow">
//           <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
//             <div className="flex h-16 justify-between">
//               <div className="flex">
//                 <div className="-ml-2 mr-2 flex items-center md:hidden">
//                   <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
//                     <span className="sr-only">Open main menu</span>
//                     <Bars3Icon
//                       aria-hidden="true"
//                       className="block h-6 w-6 group-data-[open]:hidden"
//                     />
//                     <XMarkIcon
//                       aria-hidden="true"
//                       className="hidden h-6 w-6 group-data-[open]:block"
//                     />
//                   </DisclosureButton>
//                 </div>
//                 <div className="flex flex-shrink-0 items-center">
//                   <img
//                     alt="Your Company"
//                     src={Logo}
//                     className="h-full w-auto"
//                   />
//                 </div>
//                 <div className="hidden md:ml-6 md:flex md:space-x-8">
//                   <NavLink
//                     to="/register"
//                     className={({ isActive }) =>
//                       isActive
//                         ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
//                         : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
//                     }
//                   >
//                     Register
//                   </NavLink>
//                   <NavLink
//                     to="/login"
//                     className={({ isActive }) =>
//                       isActive
//                         ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
//                         : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
//                     }
//                   >
//                     Login
//                   </NavLink>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Disclosure>
//       )}
//     </>
//   );
// }

import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";
import { deleteToken } from "../api/storage";
import Logo from "../assets/DrHLogo2.png";
import ProfilePic from "../assets/Profile.png";
import { Bars3Icon } from "@heroicons/react/24/outline";

export default function Navbar() {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    deleteToken();
    setUser(false);
    navigate("/login");
  };

  return (
    <Disclosure as="nav" className="bg-white shadow">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between items-center">
          {/* Logo Section */}
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img alt="Your Company" src={Logo} className="h-14 w-auto" />
            </div>
            <div className="hidden md:ml-6 md:flex md:space-x-8">
              {user ? (
                <>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/request"
                    className={({ isActive }) =>
                      isActive
                        ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    Request
                  </NavLink>
                  <NavLink
                    to="/fileUpload"
                    className={({ isActive }) =>
                      isActive
                        ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    File Upload
                  </NavLink>
                  <NavLink
                    to="/timeTable"
                    className={({ isActive }) =>
                      isActive
                        ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    Time Table
                  </NavLink>
                  <NavLink
                    to="/profile"
                    className={({ isActive }) =>
                      isActive
                        ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    My Profile
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="/register"
                    className={({ isActive }) =>
                      isActive
                        ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    Register
                  </NavLink>
                  <NavLink
                    to="/login"
                    className={({ isActive }) =>
                      isActive
                        ? "inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900"
                        : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    }
                  >
                    Login
                  </NavLink>
                </>
              )}
            </div>
          </div>

          {/* Right Section */}
          {user && (
            <div className="hidden md:flex items-center space-x-4">
              <button
                onClick={() => navigate("/userRequests")}
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
              >
                My Requests
              </button>
              <Menu as="div" className="relative">
                <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <img
                    alt=""
                    src={ProfilePic}
                    className="h-8 w-8 rounded-full"
                  />
                </MenuButton>
                <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                  <MenuItem>
                    <NavLink
                      to="/profile"
                      className="block w-full px-4 py-2 text-sm text-gray-700 text-left"
                    >
                      My Profile
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-sm text-gray-700 text-left"
                    >
                      Sign out
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          )}

          {/* Mobile Menu Button */}
          <div className="flex items-center md:hidden">
            <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            </Disclosure.Button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <Disclosure.Panel className="md:hidden">
        <div className="space-y-1 pb-3 pt-2">
          {user ? (
            <>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/request"
                className={({ isActive }) =>
                  isActive
                    ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                }
              >
                Request
              </NavLink>
              <NavLink
                to="/fileUpload"
                className={({ isActive }) =>
                  isActive
                    ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                }
              >
                File Upload
              </NavLink>
              <NavLink
                to="/timeTable"
                className={({ isActive }) =>
                  isActive
                    ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                }
              >
                Time Table
              </NavLink>
              <NavLink
                to="/profile"
                className={({ isActive }) =>
                  isActive
                    ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                }
              >
                My Profile
              </NavLink>
              <button
                onClick={handleLogout}
                className="block w-full border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Sign out
              </button>
            </>
          ) : (
            <>
              <NavLink
                to="/register"
                className={({ isActive }) =>
                  isActive
                    ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                }
              >
                Register
              </NavLink>
              <NavLink
                to="/login"
                className={({ isActive }) =>
                  isActive
                    ? "block border-l-4 border-sky-500 bg-sky-50 py-2 pl-3 pr-4 text-base font-medium text-sky-700"
                    : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
                }
              >
                Login
              </NavLink>
            </>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}

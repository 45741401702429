import React, { useEffect, useState, useContext } from "react";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { fetchUserData, posttable } from "../api/auth";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext"; // Assuming you have a UserContext for managing user authentication

export default function FileUploadForm() {
  const [userInfo, setUserInfo] = useState({
    civilID: "",
    fullName: "",
    phoneNumber: "",
    fileNumber: "",
    center: "",
    supervisor: "",
  });

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null); // State for preview
  const [user] = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      // If there is no user, redirect to the login page
      navigate("/login");
    } else {
      const getUserData = async () => {
        try {
          const data = await fetchUserData();
          setUserInfo({
            civilID: data.civilID || "",
            fullName: data.fullName || "",
            phoneNumber: data.phoneNumber || "",
            fileNumber: data.fileNumber || "",
            center: data.center || "",
            supervisor: data.supervisor || "",
          });
        } catch (error) {
          console.error("Failed to fetch user data:", error.message);
        }
      };

      getUserData();
    }
  }, [user, navigate]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Generate a preview if the file is an image
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setFilePreview(fileReader.result);
      };
      fileReader.readAsDataURL(selectedFile);
    } else {
      setFilePreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      const response = await posttable(file);
      alert("File uploaded successfully: " + response.message);
      setFile(null);
      setFilePreview(null);
      navigate("/fileUpload");
    } catch (error) {
      alert("Error uploading file: " + error.message);
    }
  };

  return (
    user && ( // Render the form only if the user is authenticated
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                Upload File Form
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This information will be displayed publicly, so be careful what
                you share.
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <label
                  htmlFor="civilID"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Civil ID
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="civilID"
                      name="civilID"
                      type="text"
                      value={userInfo.civilID}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="fullName"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Full Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="fullName"
                      name="fullName"
                      type="text"
                      value={userInfo.fullName}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phoneNumber"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  phone number
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      value={userInfo.phoneNumber || ""}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="fileNumber"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  File Number
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="fileNumber"
                      name="fileNumber"
                      type="text"
                      value={userInfo.fileNumber || ""}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="center"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Center
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="center"
                      name="center"
                      type="text"
                      value={userInfo.center}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, center: e.target.value })
                      }
                      placeholder="Center Name"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="supervisor"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  supervisor
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="supervisor"
                      name="supervisor"
                      type="text"
                      value={userInfo.supervisor}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, supervisor: e.target.value })
                      }
                      placeholder="supervisor Name"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="file-upload"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Upload your file
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <PhotoIcon
                      aria-hidden="true"
                      className="mx-auto h-12 w-12 text-gray-300"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 hover:text-sky-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          onChange={handleFileChange}
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, PDF up to 10MB
                    </p>
                    {filePreview && (
                      <div className="mt-4">
                        <img
                          src={filePreview}
                          alt="Preview"
                          className="mx-auto h-24 w-24 rounded-md"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
          >
            Send
          </button>
        </div>
      </form>
    )
  );
}

import React, { useEffect, useState } from "react";
import { getTable } from "../api/auth";

const TimeTable = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLatestFile = async () => {
      try {
        const data = await getTable(); // Call the function instead of referencing it

        setPdfFile(data?.path || null); // Use the file path

        setError(null);
      } catch (err) {
        console.error(
          "Error fetching file:",
          err.response?.data || err.message
        );
        if (err.response?.status === 404) {
          setError("No files found in the tables folder.");
        } else {
          setError("Failed to load the latest file. Please try again.");
        }
      }
    };

    fetchLatestFile();
  }, []);

  return (
    <div className="p-6">
      <h1>Show Table</h1>
      {pdfFile ? (
        <iframe
          src={pdfFile}
          title="Table PDF"
          className="w-full h-screen"
          frameBorder="0"
        />
      ) : (
        <p className="text-center text-gray-500">
          No PDF available. Please upload a PDF to view it here.
        </p>
      )}
    </div>
  );
};

export default TimeTable;

import React from "react";
import FileUploadFrom from "../components/FileUploadFrom";

const FileUpload = () => {
  return (
    <div className="flex min-h-full py-12 px-4 sm:px-6 lg:px-8">
      <FileUploadFrom />
    </div>
  );
};

export default FileUpload;
